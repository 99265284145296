<template>
  <div class="flex-row-fluid d-flex flex-column ml-lg-8">
    <div class="card card-custom gutter-b">
      <!--begin::Body-->
      <div class="card-body d-flex align-items-center justify-content-between flex-wrap py-3">
        <!--begin::Info-->
        <div class="d-flex align-items-center mr-2 py-2">
          <!--begin::Title-->
          <h3 class="font-weight-bold mb-0 mr-10">Client Company</h3>
          <!--end::Title-->
          <div class="input-icon">
            <input type="text" class="form-control" placeholder="Search..." v-model="search" />
            <span>
              <i class="flaticon2-search-1 text-muted"></i>
            </span>
          </div>
        </div>
        <!--end::Info-->
        <!--begin::Users-->
        <router-link to="/client-company/create" tag="button" class="btn btn-primary font-weight-bolder">
          <span class="svg-icon svg-icon-md">
            <!--begin::Svg Icon | path:assets/media/svg/icons/Design/Flatten.svg-->
            <inline-svg src="/media/svg/icons/Design/Flatten.svg"></inline-svg> </span
          >New Company</router-link
        >
      </div>
      <!--end::Body-->
    </div>

    <div v-if="paginatedData.length > 0">
      <transition-group name="list" tag="div" class="row">
        <div class="col-sm-6 col-lg-4 col-xl-3" v-for="company in paginatedData" :key="company.id">
          <!--begin::Card-->
          <client-company-card :company="company" @delete-company="toggleCompanyStatus" />
          <!--end::Card-->
        </div>
      </transition-group>

      <pagination v-model="pagination" />
    </div>

    <no-data v-else></no-data>
  </div>
</template>

<script>
import { getAllClientCompany, lockClientCompany, unlockClientCompany } from "@/api/client.api";

import Pagination from "@/components/general/Pagination";
import ClientCompanyCard from "./components/ClientCompanyCard";

import { ref, reactive, onMounted, computed } from "@vue/composition-api";
import Swal from "sweetalert2";

export default {
  name: "listClientCompany",
  components: { Pagination, ClientCompanyCard },

  setup() {
    let list = ref([]);
    const search = ref("");

    onMounted(() => refresh());

    const refresh = async () => {
      const response = await getAllClientCompany();
      list.value = response.data;
    };

    const pagination = reactive({
      currentPage: 1,
      totalRows: computed(() => list.value.length),
      showing: computed(() => paginatedData.value.length),
      itemInPage: 10
    });

    const paginatedData = computed(() => {
      const searchTerm = search.value.toLowerCase();
      let filteredData = list.value.filter(company => {
        for (const key of Object.keys(company)) {
          const val = company[key].toString().toLowerCase();
          if (val.includes(searchTerm)) {
            return true;
          }
        }
        return false;
      });

      let index = (pagination.currentPage - 1) * pagination.itemInPage;
      return filteredData.slice(index, index + pagination.itemInPage);
    });

    const toggleCompanyStatus = (id, status) => {
      if (status == "ACTIVE") {
        Swal.fire({
          title: "Confirm deactivate?",
          text: "You may re-activate this company later ",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes!"
        }).then(async result => {
          if (result.isConfirmed) {
            await lockClientCompany(id);
            refresh();
            Swal.fire("Lock!", "This company have been deactivated", "success");
          }
        });
      } else {
        Swal.fire({
          title: "Confirm Activate?",
          text: "You may de-activate this company later ",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes!"
        }).then(async result => {
          if (result.isConfirmed) {
            await unlockClientCompany(id);
            refresh();
            Swal.fire("Unlock!", "This company have been activated", "success");
          }
        });
      }
    };

    return { search, pagination, paginatedData, toggleCompanyStatus };
  }
};
</script>

<style scoped>
.list-enter,
.list-leave-to {
  opacity: 0;
}
.list-enter-active,
.list-leave-active {
  transition: opacity 0.5s ease;
}
</style>
