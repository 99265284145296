<template>
  <div class="card card-custom gutter-b card-stretch">
    <!--begin::Body-->
    <div class="card-body pt-4 d-flex flex-column justify-content-between">
      <!--begin::User-->
      <div class="d-flex align-items-center mb-7">
        <!--begin::Pic-->
        <div class="flex-shrink-0 mr-4 mt-lg-0 mt-3" v-if="company.image_path">
          <div class="symbol symbol-lg-75">
            <img alt="Pic" :src="company.image_path" />
          </div>
          <div class="symbol symbol-lg-75 symbol-primary d-none">
            <span class="font-size-h3 font-weight-boldest"></span>
          </div>
        </div>
        <!--end::Pic-->
        <!--begin::Title-->
        <div class="d-flex justify-content-between w-100">
          <p class="text-dark font-weight-bold font-size-h4 mb-0">
            {{ company.name }}
          </p>
          <p class="text-dark mb-0">
            <b-badge class="mr-1" :variant="company.status == 'ACTIVE' ? 'success' : 'danger'">{{
              company.status
            }}</b-badge>
          </p>
        </div>
        <!--end::Title-->
      </div>
      <!--end::User-->
      <!--begin::Info-->
      <div class="mb-7">
        <div class="d-flex justify-content-between align-items-center">
          <span class="text-dark-75 font-weight-bolder mr-2">Email:</span>
          <span class="text-muted font-weight-bold">{{ company.email }}</span>
        </div>
        <div class="d-flex justify-content-between align-items-cente my-1">
          <span class="text-dark-75 font-weight-bolder mr-2">Phone:</span>
          <span class="text-muted font-weight-bold">{{ company.phone }}</span>
        </div>
        <div class="d-flex justify-content-between align-items-center">
          <span class="text-dark-75 font-weight-bolder mr-2">Location:</span>
          <span class="text-muted font-weight-bold">{{ company.location }}</span>
        </div>
      </div>
      <!--end::Info-->
      <div class="d-flex align-items-center justify-content-between flex-wrap py-3">
        <div class="d-flex align-items-center mr-2 py-2">
          <router-link
            :to="{ name: 'ListClientCompanyUser', params: { id: company.id } }"
            class="btn btn-icon btn-primary mx-3"
          >
            <i class="flaticon2-group"></i>
          </router-link>
          <router-link
            :to="{ name: 'UpdateClientCompany', params: { id: company.id } }"
            class="btn btn-icon btn-success mx-3"
          >
            <i class="flaticon2-pen"></i>
          </router-link>
        </div>

        <div
          class="btn btn-icon mx-3"
          :class="company.status == 'ACTIVE' ? 'btn-danger' : 'btn-warning'"
          @click="$emit('delete-company', company.id, company.status)"
        >
          <i class="la" :class="company.status == 'ACTIVE' ? 'la-lock' : 'la-lock-open'"></i>
        </div>
      </div>
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
export default {
  name: "clientCompanyCard",

  props: ["company"]
};
</script>
